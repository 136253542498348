@use 'sass:map';

@use '@shared/styles/config/variables' as *;
@use '@shared/styles/mixins/breakpoint' as *;
@use '@shared/styles/mixins/spacing' as *;

.root {
  margin: map.get($spacers, 'spacing', '6') 0;

  [class*="Loader_loader__"] {
    > div {
      background: map.get($colors, 'purple-rain');
    }
  }
}

.card {
  [class*="Card_links__"] {
    a {
      min-width: 0;
      padding: 0;

      &:hover {
        text-decoration: underline;
        text-underline-offset: map.get($spacers, 'spacing', '1');
      }
    }
  }
}

.resetButton {
  position: absolute;
  right: 0;
  top: 0;
}

.noResults {
  margin-top: map.get($spacers, 'spacing', '6');
  display: flex;
  justify-content: center;
  flex: 1;

  span {
    padding: map.get($spacers, 'spacing', '4');
    border: map.get($borders, '1') solid map.get($colors, 'blackbird-500');
    border-radius: map.get($radiuses, '3');
  }
}

.filter {
  padding-bottom: map.get($spacers, 'spacing', '6');
  position: relative;
  z-index: 5;
}

.filterWrapper {
  position: relative;
  background-color: map.get($colors, 'lily-of-the-valley');
  padding: map.get($spacers, 'spacing', '4') map.get($spacers, 'spacing', '5');
  border-radius: map.get($radiuses, '4');
  flex: 100%;
  display: flex;
  flex-direction: row;
  gap: map.get($spacers, 'spacing', '6');

  @include breakpoint('lg', 'max') {
    flex-direction: column;
    gap: map.get($spacers, 'spacing', '3');
  }
}

.divider {
  position: relative;
  width: 1px;
  height: 100%;
  background-color: map.get($colors, 'blackbird-500');
}

.filterLabel {
  padding-bottom: map.get($spacers, 'spacing', '2');
}

.filterItem {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  button {
    width: 100%;
    justify-content: space-between;
  }

  @include breakpoint('lg', 'min') {
    flex: 1;
  }
}

.pagination {
  margin-top: map.get($spacers, 'spacing', '8');
}

.paginationButtons {
  display: flex;
  justify-content: center;
  gap: map.get($spacers, 'spacing', '4');
}

.dropdownWrapper {
  width: 100%;
  position: relative;
}

.dropdownSelect {
  display: none;
  width: 100%;
  z-index: 10;
  background-color: map.get($colors, 'white');
  border-radius: map.get($radiuses, '3');
  border: map.get($borders, '1') solid map.get($colors, 'blackbird-500');
  left: 0;
  padding: map.get($spacers, 'spacing', '4');
  position: absolute;
  top: 100%;
  white-space: nowrap;
  z-index: 10;
  max-height: 25rem;
  overflow-y: auto;

  ul {
    width: 100%;

    li {
      text-align: left;
      cursor: pointer;

      &.isActive {
        background-color: map.get($colors, 'purple-rain-tint');
        color: map.get($colors, 'purple-rain-active');
        border-radius: map.get($radiuses, '2');
      }

      &:hover {
        span {
          background-color: map.get($colors, 'purple-rain-tint');
          color: map.get($colors, 'purple-rain-active');
        }
      }

      span {
        color: map.get($colors, 'charcoal-charlie');
        padding: map.get($spacers, 'spacing', '3')
          map.get($spacers, 'spacing', '3');
        display: block;
        border-radius: map.get($radiuses, '1');
      }
    }

    @include breakpoint('md', 'max') {
      max-height: 25rem;
      overflow: auto;
    }
  }

  &.isActive {
    display: flex;
  }
}