@use 'sass:map';

@use '@shared/styles/config/variables' as *;
@use '@shared/styles/mixins/breakpoint' as *;
@use '@shared/styles/mixins/spacing' as *;


.root {
  @include spacing('lg', 'bottom', 'margin');

  li {
    &:not(:last-child) {
      margin-bottom: map.get($spacers, 'spacing', '2');
    }
  }

  button {
    cursor: pointer;
    background-color: transparent;
    color: map.get($colors, 'purple-rain');
    font-weight: map.get($typography, 'weight', 'normal');

    &:hover {
      text-decoration: underline;
      text-decoration-thickness: map.get($spacers, 'spacing', '1');
      text-underline-offset: map.get($spacers, 'spacing', '1');
    }
  }

  .title {
    margin-bottom: map.get($spacers, 'spacing', '4');
  }
}

.tocDesktop {
  display: none;

  button {
    display: none;
  }

  @include breakpoint('lg', 'min') {
    display: block;
  }
}

.tocMobile {
  [class*="Button_large__"] {
    border-radius: 0;
    border-left: 0;
    border-radius: 0;
    width: 100%;
    justify-content: space-between;
    padding: map.get($spacers, 'spacing', '4') map.get($spacers, 'spacing', '4');
    color: map.get($colors, 'charcoal-charlie');
    font-weight: map.get($typography, 'weight', 'bold');

    &:focus,
    &:active {
      text-decoration: none;
    }
  }

  @include breakpoint('lg', 'min') {
    display: none;
  }

  ul {
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.3s ease-in-out;
    

    &.tocIsActive {
      max-height: 100vh;
    }

    li {
      &:first-child {
        margin-top: map.get($spacers, 'spacing', '3');
      }
      padding: map.get($spacers, 'spacing', '3') map.get($spacers, 'spacing', '4');
    }
  }
}