@use 'sass:map';

@use '@shared/styles/config/variables' as *;

.root {
  display: flex;
  padding: map.get($spacers, 'spacing', '1') map.get($spacers, 'spacing', '3') + map.get($spacers, 'spacing', '1');
  border-radius: map.get($radiuses, '4');
  gap: map.get($spacers, 'spacing', '2');
  margin-bottom: map.get($spacers, 'spacing', '4');

  span {
    color: map.get($colors, 'gray-60');
    line-height: 1.8;
  }

  &.classic {
    align-items: flex-start;
    text-transform: uppercase;
  }

  &.company {
    font-weight: map.get($typography, 'weight', 'bold');
    display: inline-flex;
  }

  &[class*="Backgrounds_purple-rain-tint-active__"],
  &[class*="Backgrounds_purple-rain-tint__"] {
    span {
      color: map.get($colors, 'charcoal-charlie');
    }
  }
}