@use 'sass:map';

@use '@shared/styles/mixins/breakpoint' as *;
@use '@shared/styles/config/variables' as *;

$gap: map.get($spacers, 'spacing', '6');

.loadMore {
  text-align: center;
}

.masonryItem {
  display: inline-block;
  margin-bottom: $gap;
  width: 100%;
}
