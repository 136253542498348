@use 'sass:map';

@use '@shared/styles/config/variables' as *;

.root {
  padding: map.get($spacers, 'spacing', '4');
  background-color: map.get($colors, 'lavender-blush');
  border-radius: map.get($radiuses, '2');
  display: inline-flex;
  flex-direction: column;
  margin: map.get($spacers, 'spacing', '4') 0;
  
  h6 {
    flex: 1;
  }

  svg {
    margin-right: map.get($spacers, 'spacing', '2');
    margin-top: map.get($spacers, 'spacing', '1');

    path {
      fill: map.get($colors, 'error');
    }
  }
}

.messageWrapper {
  display: flex;
  flex-direction: row;
}

.actionMsg {
  margin-left: map.get($spacers, 'spacing', '5');

  a {
    color: inherit;
    text-decoration: underline;
    text-decoration-thickness: map.get($spacers, 'spacing', '1');
    text-underline-offset: map.get($spacers, 'spacing', '1');
  }
}