@use 'sass:map';

@use '@shared/styles/config/variables' as *;
@use '@shared/styles/mixins/breakpoint' as *;
@use '@shared/styles/mixins/spacing' as *;

.root {
  background-color: map.get($colors, 'white');
  border-radius: map.get($radiuses, '4');
  overflow: hidden;

  [class*='white'] & {
    background-color: map.get($colors, 'lily-of-the-valley');
  }

  &.icons {
    .editor {
      font-style: italic;
    }
  }
}

.content {
  text-align: left;
  padding: map.get($spacers, 'spacing', '4');

  @include breakpoint('md', 'min') {
    padding: map.get($spacers, 'spacing', '6');
  }

  @include breakpoint('lg', 'min') {
    padding: map.get($spacers, 'spacing', '8');
  }
}

.withoutImage {
}

.logo {
  @include spacing('md', 'bottom', 'margin');
}

.author {
  display: flex;
  flex-flow: column;
  justify-content: center;
  min-height: 3.5rem;
  position: relative;

  @include spacing('md', 'bottom', 'margin');

  img {
    border-radius: 50%;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 0;

    & ~ * {
      margin-left: map.get($spacers, 'spacing', '11');
      text-align: left;
    }
  }
}

.chip {
  @include spacing('md', 'bottom', 'margin');
}

.editor {
  @include spacing('md', 'bottom', 'margin');

  .withoutImage & {
    padding: 0;
  }
}

.link {
  @include spacing('md', 'bottom', 'margin');
}

.image {
  position: relative;
  padding: 0 !important;
  height: 100%;

  display: none;

  @include breakpoint('lg', 'min') {
    display: block;
  }

  img {
    position: absolute;
    top: 0;
    right: 0;
    border-radius: map.get($radiuses, '7') 0 0 map.get($radiuses, '7');
    object-fit: cover;
    width: calc(100% + 6rem);
    height: 100%;
    max-width: unset;
  }
}

.stats {
  display: flex;
  gap: map.get($spacers, 'spacing', '8');
  @include spacing('md', 'bottom', 'margin');
}

.logoWraper {
  padding: map.get($spacers, 'spacing', '5') 0;
  max-width: 10rem;

  img {
    height: auto;
    max-width: 100%;
  }
}
