@use 'sass:map';

@use '@shared/styles/config/variables' as *;
@use '@shared/styles/mixins/breakpoint' as *;
@use '@shared/styles/mixins/transition' as *;
@use '@shared/styles/mixins/spacing' as *;

.main {
  position: relative;

  @include breakpoint('md', 'min') {
    display: block;
  }

  @include breakpoint('lg', 'min') {
    min-height: 42.5rem;
  }

  &:hover {
    .button {
      @include breakpoint('md', 'min') {
        opacity: 1;

        @include transition('opacity');
      }
    }
  }

  [class*='swiper-initialized'] {
    border-radius: map.get($spacers, 'spacing', '4');
  }

  [class*='swiper-slide'] {
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    @include breakpoint('lg', 'min') {
      min-height: 42.5rem;
    }

    @include breakpoint('md', 'max') {
      min-height: 30rem;
    }

    .image {
      width: 100%;
      object-fit: cover;

      @include breakpoint('lg', 'min') {
        min-height: 42.5rem;
      }

      @include breakpoint('md', 'max') {
        min-height: 30rem;
      }
    }
  }

  .slideContent {
    min-height: 42.5rem;
    overflow: hidden;
    margin: 0;
  }

  [class*='swiper-slide-active'] {
    [class*='Slider_feature'] {
      -webkit-animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
        both;
      animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    }

    .feature1 {
      animation-delay: 0.75s;
    }

    .feature1 {
      animation-delay: 1s;
    }
  }
}

.caption {
  display: flex;
  align-items: center;
  justify-content: center;
  color: map.get($colors, 'white');
  z-index: 5;

  [class*='Button_'] {
    color: map.get($colors, 'white');
  }

  @include breakpoint('md', 'max') {
    display: none;
  }
}

.dot {
  margin: 0 map.get($spacers, 'spacing', '3');
}

.feature {
  position: absolute;
  max-width: 15rem;
  -webkit-animation: slide-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;

  @include breakpoint('md', 'max') {
    display: none;
  }

  img {
    height: auto;
    max-width: 100%;
  }

  a {
    margin-bottom: map.get($spacers, 'spacing', '4');
    border-radius: map.get($spacers, 'spacing', '8');

    align-self: flex-start;
    display: inline-flex;
    gap: 0.5rem;

    &.iconRight {
      flex-direction: row-reverse;
    }
  }

  &.imageAbove {
    display: flex;
    flex-direction: column-reverse;

    img {
      margin-bottom: map.get($spacers, 'spacing', '4');
    }
  }
}

.pagination {
  position: absolute;
  left: 50%;
  bottom: map.get($spacers, 'spacing', '5');
  transform: translateX(-50%);
  display: none;
  flex-direction: column;
  align-items: center;
  gap: map.get($spacers, 'spacing', '4');
  padding: map.get($spacers, 'spacing', '4');
  background-color: rgba(map.get($colors, 'mirage'), 0.5);
  border-radius: map.get($radiuses, '2');
  color: map.get($colors, 'white');
  z-index: 100;

  @include breakpoint('lg', 'min') {
    display: flex;
  }
}

.paginationInner {
  display: flex;
  gap: map.get($spacers, 'spacing', '3');
}

.pill {
  width: 100px;
  height: map.get($spacers, 'spacing', '3');
  border-radius: map.get($radiuses, '1');
  background-color: map.get($colors, 'lily-of-the-valley');
  border: 0;
  cursor: pointer;
}

.current {
  background-color: map.get($colors, 'purple-rain');
}

@-webkit-keyframes slide-top {
  0% {
    -webkit-transform: translateY(30rem);
    transform: translateY(30rem);
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes slide-top {
  0% {
    -webkit-transform: translateY(30rem);
    transform: translateY(30rem);
  }

  100% {
    -webkit-transform: translateY(-0);
    transform: translateY(0);
  }
}

@-webkit-keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  100% {
    -webkit-transform: translateY(30rem);
    transform: translateY(30rem);
  }
}

@keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  100% {
    -webkit-transform: translateY(30rem);
    transform: translateY(30rem);
  }
}
