@use 'sass:map';

@use '@shared/styles/config/variables' as *;

.root {
  background-color: map.get($colors, 'white');
  border-radius: map.get($radiuses, '4');
  border-style: solid;
  border-width: map.get($borders, '1') map.get($borders, '1') map.get($borders, '1') map.get($borders, '2');
  color: map.get($colors, 'charcoal-charlie');
  display: inline-flex;
  font-size: map.get($typography, 'size', '2');
  padding: map.get($spacers, 'spacing', '1') * 1.5 map.get($spacers, 'spacing', '4');

  &[href] {
    &:hover {
      border-color: map.get($colors, 'purple-rain-active');
      color: map.get($colors, 'purple-rain-active');
      text-decoration: none;
    }

    &:active {
      transform: translateY(map.get($spacers, 'spacing', '1'));
    }
  }
}
