@use 'sass:map';
@use 'sass:math';

@use '@shared/styles/config/variables' as *;
@use '@shared/styles/mixins/breakpoint' as *;
@use '@shared/styles/mixins/spacing' as *;

.root {
  position: relative;

  @include breakpoint('md', 'max') {
    display: flex;
    flex-direction: column;
  }
}

.cover {
  display: block;
  overflow: hidden;
  background-color: map.get($colors, 'surface');

  @media screen and (max-width: 30em) {
    padding-top: 186.67%;
  }

  @media screen and (min-width: 30em) {
    padding-top: 146.67%;
  }

  .desktop {
    display: block;

    &.hideOnMobile {
      @include breakpoint('md', 'max') {
        display: none;
      }
    }
  }

  .mobile {
    display: none;

    @include breakpoint('md', 'max') {
      display: block;
    }
  }

  @include breakpoint('sm', 'min') {
    padding-top: calc(100vw / 0.935);
  }

  @include breakpoint('md', 'min') {
    padding-top: 56.25%;
    padding-top: calc(600 / 1360 * 100%);
    height: 100%;

    &:after {
      content: '';
      display: block;
      left: 0;
      width: 100%;
      position: absolute;
      height: 100%;
      top: 0;
      background: map.get($colors, 'surface');
      background: linear-gradient(
        90deg,
        rgba(41, 55, 69, 1) 0%,
        rgba(41, 55, 69, 1) 25%,
        rgba(41, 55, 69, 0) 75%
      );
      z-index: 0;
    }
  }

  @include breakpoint('xl', 'min') {
    padding-top: 37.5rem;
  }

  img {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;

    @include breakpoint('md', 'max') {
      object-position: 80%;
    }
    @include breakpoint('lg', 'min') {
      object-position: right;
    }
  }
}

.testimonial {
  color: map.get($colors, 'white');
  text-align: left;
  left: 0;
  position: absolute;
  top: 0;
  padding: map.get($spacers, 'spacing', '4');

  @include breakpoint('md', 'min') {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    max-width: 80%;
  }

  @include breakpoint('lg', 'min') {
    max-width: 45%;
  }

  @include breakpoint('xl', 'min') {
    max-width: 30%;
    left: 6rem;
  }

  svg {
    color: map.get($colors, 'yellow-submarine');
    margin-bottom: map.get($spacers, 'spacing', '4');
  }
}

.cta {
  margin-top: map.get($spacers, 'spacing', '4');

  @include breakpoint('sm', 'max') {
    flex: 1;
    display: flex;
  }
}

.editor {
  font-style: italic;
  margin-bottom: map.get($spacers, 'spacing', '4');
  line-height: normal;
  font-weight: map.get($typography, 'weight', 'bold');
}
