@use 'sass:map';
@use 'sass:math';

@use '@shared/styles/config/variables' as *;
@use '@shared/styles/mixins/breakpoint' as *;
@use '@shared/styles/mixins/spacing' as *;

// ... Begin Mobile dropdown
.mobileDropdown {
  position: relative;
  margin-bottom: map.get($spacers, 'spacing', '6');

  select {
    appearance: none;
    background-color: map.get($colors, 'white');
    border-radius: map.get($radiuses, '2');
    border: map.get($borders, '1') solid map.get($colors, 'blackbird-500');
    padding: math.div(map.get($spacers, 'spacing', '5'), 2)
      map.get($spacers, 'spacing', '4');
    width: 100%;
  }

  svg {
    position: absolute;
    top: 50%;
    right: 0;
    width: auto;
    height: 100%;
    padding: 1em;
    transform: translateY(-50%);
    pointer-events: none;
  }

  @include breakpoint('lg', 'min') {
    display: none;
  }
}
// ... End Mobile dropdown

.tabs {
  list-style: none;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  gap: map.get($spacers, 'spacing', '4');
  overflow-x: auto;
  min-width: 100%;

  @include breakpoint('lg', 'max') {
    display: none;
  }

  @include spacing('md', 'bottom', 'padding');

  li {
    white-space: nowrap;

    button {
      background-color: transparent;
      background-color: map.get($colors, 'white');
      border: map.get($borders, '1') + map.get($borders, '1') solid
        map.get($colors, 'lily-of-the-valley');

      &.iconTab {
        color: map.get($colors, 'charcoal-charlie');
        display: flex;
        flex-direction: column;
        gap: map.get($spacers, 'spacing', '3');
        justify-content: center;
        padding: 0.85rem 0.5rem;
        min-width: 12rem;
        width: 100%;
        height: 100%;
      }

      &.is-active {
        background-color: map.get($colors, 'white');
        border-color: map.get($colors, 'purple-rain');

        [class*='white'] & {
          background-color: map.get($colors, 'lily-of-the-valley');
        }
      }

      &:hover {
        border: map.get($borders, '1') + map.get($borders, '1') solid
          map.get($colors, 'purple-rain');
      }
    }
  }

  img {
    object-fit: contain;

    @include breakpoint('lg', 'max') {
      height: 2.5rem;
      width: 5rem;
    }
  }
}

.tab {
  display: none;

  &.is-active {
    display: block;
  }

  [class*='Stats_root__'] {
    div {
      &:first-child {
        margin-bottom: map.get($spacers, 'spacing', '3');
      }
      &:nth-child(2) {
        padding: 0 0;
      }
    }
  }

  [class*='Testimonial_content__'] {
    // padding: map.get($spacers, 'spacing', '4') map.get($spacers, 'spacing', '2');

    // @include breakpoint('sm', 'max') {
    //   padding-top: 0;
    // }
  }

  [class*='Testimonial_editor__'] {
    @include breakpoint('lg', 'min') {
      padding-right: map.get($spacers, 'spacing', '12');
    }
  }

  [class*='Testimonial_image__'] {
    display: none;

    @include breakpoint('lg', 'min') {
      display: block;
      // margin-left: - map.get($spacers, 'spacing', '12');
    }
  }

  [class*='Testimonial_stats__'] {
    @include breakpoint('sm', 'max') {
      flex-direction: column;
      gap: map.get($spacers, 'spacing', '6');

      &:not(:last-child) {
        margin-bottom: map.get($spacers, 'spacing', '6');
      }
    }
  }

  [class*='Testimonial_link'] {
    @include breakpoint('sm', 'max') {
      width: 100%;
    }
  }
}

.root {
  &.icons {
    [class*='Testimonial_editor__'] {
      padding-top: map.get($spacers, 'spacing', '4');
      margin-bottom: map.get($spacers, 'spacing', '3');
    }

    [class*='Testimonial_author__'] {
      padding-bottom: map.get($spacers, 'spacing', '4');

      > div {
        font-size: map.get($typography, 'size', '2');
      }
    }
  }

  &.simple {
    .tabs {
      justify-content: center;

      li {
        flex: none;
      }
    }
  }
}

.singleTestimonial {
  [class*='Testimonial_content__'] {
    padding: map.get($spacers, 'spacing', '4') map.get($spacers, 'spacing', '6');
  }
}
