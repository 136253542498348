@use 'sass:map';
@use 'sass:math';

@use '@shared/styles/config/variables' as *;

.root {
  appearance: none;
  background-color: map.get($colors, 'white');
  border-radius: map.get($radiuses, '2');
  border: map.get($borders, '1') solid map.get($colors, 'blackbird-500');
  font-size: map.get($typography, 'size', '2');
  padding: math.div(map.get($spacers, 'spacing', '5'), 2) map.get($spacers, 'spacing', '4');
  width: 100%;

  &:focus {
    border-color: map.get($colors, 'purple-rain');
    box-shadow: 0 0 0 map.get($borders, '1') map.get($colors, 'purple-rain');
  }

  &[type="radio"] {
    display: flex;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: map.get($spacers, 'spacing', '5');
    height: map.get($spacers, 'spacing', '5');
    border: map.get($borders, '1') solid  map.get($colors, 'purple-rain');
    border-radius: map.get($spacers, 'spacing', '8');
    position: relative;
    padding: map.get($spacers, 'spacing', '3');
    cursor: pointer;

    &::before {
      content: "";
      position: absolute;
      width: map.get($spacers, 'spacing', '4');
      height: map.get($spacers, 'spacing', '4');
      border-radius: 50%;
      background-color: map.get($colors, 'transparent');
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &:checked {
      &::before {
        background-color: map.get($colors, 'purple-rain');
      }
    }

    &:disabled {
      opacity: 0.6;
      cursor: not-allowed;
    }
  }

}

.inputWrapper {
  margin-bottom: map.get($spacers, 'spacing', '4');
  position: relative;

  label {
    font-weight: map.get($typography, 'weight', 'medium');
    font-size: map.get($typography, 'size', '2');
  }
}

.errorMessage {
  display: block;
  padding: map.get($spacers, 'spacing', '1') 0;
  color: map.get($colors, 'error');
}

.error {
  input {
     border: map.get($borders, '1') solid map.get($colors, 'error');
  }
}

.after {
  position: absolute;
  right: 0.75rem;
  top: 0.75rem;
}