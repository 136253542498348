@use 'sass:map';

@use '@shared/styles/config/variables' as *;
@use '@shared/styles/mixins/breakpoint' as *;
@use '@shared/styles/mixins/spacing' as *;

.root {
  display: flex;
  align-items: center;
  flex-flow: column;
  background-color: map.get($colors, 'white');
  border-radius: map.get($radiuses, '4');
  border: map.get($borders, '1') solid map.get($colors, 'blackbird-500');
  padding: map.get($spacers, 'spacing', '4');
  text-align: center;

  @include breakpoint('lg', 'min') {
    padding: map.get($spacers, 'spacing', '5');
  }

  &.vertical {
    justify-content: center;

    .editor {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
}

.stretch {
  min-height: 100%;
}

.small {
  @include breakpoint('lg', 'min') {
    align-items: flex-start;
    text-align: left;
  }
}

.chip {
  @include spacing('md', 'bottom', 'margin');
}

.editor {
  @include spacing('md', 'bottom', 'margin');

  .horizontal & {
    @include breakpoint('lg', 'min') {
      padding: 0 map.get($spacers, 'spacing', '11');
      position: relative;
    }
  }
}

.icon {
  color: map.get($colors, 'blackbird-500');
  display: flex;

  .horizontal & {
    @include breakpoint('lg', 'max') {
      justify-content: center;
    }

    @include breakpoint('lg', 'min') {
      position: absolute;
    }
  }

  .vertical & {
    justify-content: center;
  }

  &:first-child {
    .horizontal & {
      @include breakpoint('lg', 'max') {
        margin-bottom: map.get($spacers, 'spacing', '4');
      }

      @include breakpoint('lg', 'min') {
        left: 0;
        top: 0;
      }
    }

    .vertical & {
      margin-bottom: map.get($spacers, 'spacing', '4');
    }
  }

  &:last-child {
    .horizontal & {
      @include breakpoint('lg', 'max') {
        margin-top: map.get($spacers, 'spacing', '4');
      }

      @include breakpoint('lg', 'min') {
        top: 0;
        right: 0;
      }
    }

    .vertical & {
      margin-top: map.get($spacers, 'spacing', '4');
    }

    svg {
      transform: rotate(-180deg);
    }
  }
}

.link {
  @include spacing('md', 'bottom', 'margin');
}

.logo {
  height: auto;
  width: auto;

  @include spacing('md', 'bottom', 'margin');
}

.author {
  display: flex;
  flex-flow: column;
  justify-content: center;
  min-height: 3.5rem;
  position: relative;

  @include spacing('md', 'bottom', 'margin');

  .horizontal & {
    @include breakpoint('lg', 'max') {
      text-align: center;
    }
  }

  .vertical & {
    text-align: center;
  }

  img {
    border-radius: 50%;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 0;

    & ~ * {
      margin-left: map.get($spacers, 'spacing', '11');
      text-align: left;
    }
  }
}

.gridQuote {
  box-shadow: map.get($shadows, '1');
  gap: map.get($spacers, 'spacing', '4');
  text-align: left;
  align-items: start;

  .editor {
    padding: 0;
    margin: 0;
  }

  [class*='Video_root'],
  [class*='Quote_image'] {
    width: 100%;
  }

  .image {
    position: relative;
    aspect-ratio: 16 / 9;
    border-radius: map.get($radiuses, '4');
    overflow: hidden;

    img {
      object-fit: cover;
    }
  }

  .author {
    text-align: left;
    margin: 0;
  }
}
