@use 'sass:map';
@use 'sass:math';

@use '@shared/styles/config/variables' as *;
@use '@shared/styles/mixins/breakpoint' as *;
@use '@shared/styles/mixins/spacing' as *;

.navButton {
  align-items: center;
  appearance: none;
  backdrop-filter: blur(map.get($spacers, 'spacing', '4'));
  background-color: map.get($colors, 'white');
  border-radius: map.get($radiuses, '2');
  color: map.get($colors, 'purple-rain');
  cursor: pointer;
  display: flex;
  height: 3rem;
  justify-content: center;
  padding: 0;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  width: 3rem;
  z-index: 1;
  // Prevent touch feedback from causing layout shifts
  -webkit-tap-highlight-color: transparent;
  touch-action: manipulation;
  user-select: none;
  transition: opacity 0.2s ease;

  &:hover {
    background-color: map.get($colors, 'purple-rain');
    color: map.get($colors, 'white');
  }

  &:active {
    transform: translateY(-50%) !important;
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}

.navigation {
  display: flex;
  gap: map.get($spacers, 'spacing', '4');
  align-items: center;
}
