@use 'sass:map';

@use '@shared/styles/config/variables' as *;
@use '@shared/styles/mixins/breakpoint' as *;
@use '@shared/styles/mixins/spacing' as *;

.root {
  background-color: map.get($colors, 'white');
  border-radius: map.get($radiuses, '4');
  border: map.get($borders, '1') solid map.get($colors, 'blackbird-500');
  display: flex;
  flex-flow: column;
  padding: map.get($spacers, 'spacing', '4');
  height: 100%;

  @include breakpoint('lg', 'min') {
    padding: map.get($spacers, 'spacing', '5');
  }

  [class*='Button_link__'] {
    min-width: auto;
    padding: 0 0;
    min-height: auto;
  }
}

.left {
  align-items: flex-start;
  text-align: left;
}

.center {
  align-items: center;
  text-align: center;
}

.stretch {
  min-height: 100%;
}

.categories {
  display: flex;
  flex-wrap: wrap;
  margin: 0 (- map.get($spacers, 'spacing', '3'))
    (- map.get($spacers, 'spacing', '4')) 0;

  @include spacing('md', 'bottom', 'padding');

  .center & {
    justify-content: center;
  }

  li {
    padding: 0 map.get($spacers, 'spacing', '3')
      map.get($spacers, 'spacing', '4') 0;
  }
}

.chip {
  @include spacing('md', 'bottom', 'margin');
}

.editor {
  @include spacing('md', 'bottom', 'margin');
}

.description {
  @include spacing('md', 'bottom', 'margin');

  line-clamp: 6;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
}

.highlight {
  @include spacing('md', 'bottom', 'margin');
}

.icon {
  display: block;
  width: 2.25rem;

  &.iconWithCustomHeight {
    width: auto;
  }

  @include spacing('md', 'bottom', 'margin');

  img {
    object-fit: contain;
    height: 5rem;
  }
}

.border {
  background-color: map.get($colors, 'white');
  border-radius: map.get($radiuses, '3');
  border: map.get($borders, '1') solid map.get($colors, 'blackbird-500');
  padding: map.get($spacers, 'spacing', '4');
  width: 100%;

  img {
    margin: 0 auto;
  }
}

.image {
  display: block;
  padding-top: 50%;
  position: relative;
  width: 100%;

  @include spacing('md', 'bottom', 'margin');

  img {
    border-radius: map.get($radiuses, '3');
    display: block;
    height: 100%;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.links {
  margin-top: auto;
  width: 100%;

  @include spacing('md', 'bottom', 'margin');

  li {
    flex-basis: 100%;
  }

  a {
    min-width: 100%;
  }
}

.title {
  @include spacing('sm', 'bottom', 'margin');
  word-break: break-word;

  a {
    &:not(:hover) {
      color: map.get($colors, 'charcoal-charlie');
    }
  }
}

.logo {
  background-color: map.get($colors, 'white');
  box-shadow: map.get($shadows, '1');
  border: 0;
  border-radius: map.get($radiuses, '4');
  padding: map.get($spacers, 'spacing', '4') map.get($spacers, 'spacing', '8');
  width: 100%;
  height: 100%;
  max-height: map.get($spacers, 'spacing', '14') +
    map.get($spacers, 'spacing', '4');
  justify-content: center;
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  @include spacing('md', 'bottom', 'margin');

  img {
    margin: 0 auto;
    width: map.get($spacers, 'spacing', '14') +
      map.get($spacers, 'spacing', '14');
    height: map.get($spacers, 'spacing', '12');
    object-fit: contain;
  }
}

.chips {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: map.get($spacers, 'spacing', '3');

  .chip {
    &:not(:last-child) {
      margin-bottom: 0;
    }
  }

  @include spacing('md', 'bottom', 'margin');
}

.date {
  @include spacing('sm', 'bottom', 'margin');
}

.eventDetails {
  @include spacing('md', 'bottom', 'margin');

  ul {
    display: flex;
    flex-direction: column;

    li {
      display: flex;
      flex-direction: row;
      gap: map.get($spacers, 'spacing', '3');
      align-items: center;

      i {
        svg {
          color: map.get($colors, 'purple-rain');
        }
      }

      &:not(:last-child) {
        margin-bottom: map.get($spacers, 'spacing', '3');
      }
    }
  }
}

.titleInline {
  display: flex;
  gap: map.get($spacers, 'spacing', '4');
  align-content: center;
  justify-content: center;
  align-items: center;

  i,
  a,
  div {
    flex-grow: 1;
  }
}

.cardLink {
  color: inherit;

  &:hover {
    text-decoration: none;
    box-shadow: map.get($shadows, '1');
    cursor: pointer;

    .editor {
      color: map.get($colors, 'charcoal-charlie');
    }

    a {
      text-decoration: underline;
      color: map.get($colors, 'purple-rain-hover');
    }

    i {
      text-decoration: underline;
    }

    h3 {
      color: map.get($colors, 'purple-rain-hover');
      text-decoration: underline;
    }
  }
}

.learnMore {
  display: flex;
  gap: map.get($spacers, 'spacing', '2');
  align-items: center;
  margin-top: auto;
  font-weight: 500;

  svg {
    width: 12px;
    height: 12px;
  }
}
