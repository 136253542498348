@use 'sass:map';

@use '@shared/styles/config/variables' as *;
@use '@shared/styles/mixins/breakpoint' as *;
@use '@shared/styles/mixins/spacing' as *;

.root {
  background-color: map.get($colors, 'lily-of-the-valley');
  padding-top: map.get($spacers, 'spacing', '7');

  @include breakpoint('lg', 'min') {
    padding-top: map.get($spacers, 'spacing', '12');
  }

  @include breakpoint('xl', 'min') {
    padding-top: map.get($spacers, 'spacing', '15');
  }

  .title {
    @include spacing('lg', 'bottom', 'margin');
  }

  > * {
    margin-top: - (map.get($spacers, 'spacing', '7') + 16.25rem);

    @include breakpoint('lg', 'min') {
      margin-top: - (map.get($spacers, 'spacing', '12') + 15rem);
    }

    @include breakpoint('xl', 'min') {
      margin-top: - (map.get($spacers, 'spacing', '15') + 16.25rem);
    }
  }
}