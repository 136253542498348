@use 'sass:map';

@use '@shared/styles/config/variables' as *;
@use '@shared/styles/mixins/breakpoint' as *;
@use '@shared/styles/mixins/spacing' as *;


/* Modal Overlay */
.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow-y: scroll;
}

.modal {
  background-color: map.get($colors, 'white');
  padding: map.get($spacers, 'spacing', '8');
  border-radius: map.get($borders, '3');
  box-shadow: map.get($shadows, '2');
  position: relative;
  z-index: 5;
  min-width: calc(100% - 2rem);
  position: absolute;
  top: map.get($spacers, 'spacing', '4')*3;

  &.middle {
    top: 50%;
    transform: translateY(-50%);
  }

  @include breakpoint('sm', 'max') {
    top: 0;
    height: 100%;
  }
  
  @include breakpoint('lg', 'min') {
    min-width: 40rem;
  }

  &.medium {
    @include breakpoint('lg', 'min') {
      max-width: 50rem;
    }
  }
}

.closeButton {
  position: absolute;
  top: map.get($spacers, 'spacing', '4');
  right: map.get($spacers, 'spacing', '4');
}

.modalContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  img {
    border-radius: map.get($radiuses, '3');
    height: auto;
    max-height: 60vh;
    width: 100%;
  }
}

.cta {
  justify-content: center;
  display: flex;
  gap: map.get($spacers, 'spacing', '4');
  width: 100%;

  @include spacing('md', 'top', 'margin');

  @include breakpoint('sm', 'max') {
    flex-direction: column;
  }
}

.image {
  margin: map.get($spacers, 'spacing', '5') 0;
}

.title {
  @include spacing('md', 'bottom', 'margin');
}

.icon {
  @include spacing('md', 'bottom', 'margin');
}