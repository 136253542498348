@use 'sass:map';
@use 'sass:math';

@use '@shared/styles/config/variables' as *;
@use '@shared/styles/mixins/breakpoint' as *;
@use '@shared/styles/mixins/spacing' as *;

@use '@shared/styles/extends/form' as *;

$flex-gap: map.get($spacers, 'spacing', '4');

.root {
  @extend %form;

  text-align: left;

  [class*='Loader_loader__'] {
    >div {
      background: map.get($colors, 'purple-rain');
    }
  }

  fieldset {
    border: none;
  }

  form {
    display: flex;
    flex-direction: column;

    [class*='hs-form-field'],
    [class*='legal-consent-container'] {
      margin-bottom: $flex-gap;
    }
  }

  // ... Layout styling
  [class*='columns'] {
    display: flex;
    flex-wrap: nowrap;
    gap: $flex-gap;

    [class*='hs-input']:not([type='checkbox'], [type='radio']) {
      width: 100% !important;
      float: unset !important;
    }

    >* {
      width: 100%;
    }
  }

  [class='input'] {
    margin-right: 0 !important;
    width: 100% !important;

    [class='hs-input']:not([type='checkbox'], [type='radio']) {
      width: 100% !important;
    }
  }

  [class*='columns-2'] {
    @include breakpoint('sm', 'max') {
      flex-direction: column;
    }
  }

  [class*='columns-3'] {
    @include breakpoint('md', 'max') {
      flex-direction: column;
    }
  }

  [class*='legal-consent-container'] {
    [class*='hs-richtext'] {
      font-size: map.get($typography, 'size', '1');
    }
  }

  fieldset {
    max-width: 100% !important;
  }

  [class*='hs-form-field']>label {
    font-size: map.get($typography, 'size', '2');
    font-weight: map.get($typography, 'weight', 'medium');
    margin-bottom: map.get($spacers, 'spacing', '2');
  }

  [class*='wpforms-field-radio'] {
    li {
      margin-top: map.get($spacers, 'spacing', '3');
    }
  }

  [class*='hs-fieldtype-radio'],
  [class*='hs-fieldtype-checkbox'] [class*='inputs-list'] {
    li {
      margin-top: map.get($spacers, 'spacing', '3');
    }
  }

  [class*='hs-error-msg'] {
    color: map.get($colors, 'error');
    font-size: map.get($typography, 'size', '1');
  }

  [class*='hs-form-required'] {
    color: map.get($colors, 'error');
  }

  [class*='hs-form-checkbox'],
  [class*='hs-form-radio'],
  [class*='hs-form-booleancheckbox'] {
    label {
      cursor: pointer;
      display: inline-flex;
      font-size: map.get($typography, 'size', '2');
      padding-left: math.div(map.get($spacers, 'spacing', '9'), 2);
      position: relative;

      &::before {
        background-color: map.get($colors, 'white');
        border: map.get($borders, '1') solid map.get($colors, 'blackbird-500');
        content: '';
        height: 1.125rem;
        left: 0;
        position: absolute;
        top: map.get($spacers, 'spacing', '1');
        width: 1.125rem;
      }

      &:has(input:checked) {
        &::before {
          border-color: map.get($colors, 'purple-rain');
        }

        &::after {
          background-color: map.get($colors, 'purple-rain');
          content: '';
          height: 0.625rem;
          left: map.get($spacers, 'spacing', '2');
          position: absolute;
          top: math.div(map.get($spacers, 'spacing', '5'), 4);
          width: 0.625rem;
        }
      }

      &[class*='hs-form-radio-display'] {

        &::before,
        &::after {
          border-radius: 50%;
        }
      }

      &[class*='hs-form-checkbox-display'] {

        &::before,
        &::after {
          border-radius: 0.1875rem;
        }
      }
    }
  }

  [type='submit'] {
    appearance: none;
    background-color: map.get($colors, 'purple-rain');
    border-radius: map.get($radiuses, '3');
    border: 0;
    color: map.get($colors, 'white');
    cursor: pointer;
    display: block;
    font-size: map.get($typography, 'size', '3');
    font-weight: map.get($typography, 'weight', 'medium');
    padding: calc(math.div(map.get($spacers, 'spacing', '7'), 3) - map.get($spacers,
          'spacing',
          '1'
        )) map.get($spacers, 'spacing', '4');
    text-align: center;
    width: 100%;

    &:hover {
      background-color: map.get($colors, 'purple-rain-hover');
      color: map.get($colors, 'white');
    }

    &:active {
      background-color: map.get($colors, 'purple-rain-active');
      transform: translateY(map.get($spacers, 'spacing', '1'));
    }

    &:disabled {
      background-color: map.get($colors, 'purple-rain-hover');
      cursor: not-allowed;
    }
  }

  &.hideForm {
    display: none;
  }
}

.thankyou {
  display: flex;
  flex-direction: column;

  h1,
  h2,
  h3,
  h4 {
    margin-bottom: map.get($spacers, 'spacing', '3');
  }
}