@use 'sass:map';

@use '@shared/styles/config/variables' as *;
@use '@shared/styles/mixins/breakpoint' as *;

.root {
  padding: map.get($spacers, 'spacing', '4') 0 map.get($spacers, 'spacing', '8')
    0;
}

.heading {
  margin-bottom: map.get($spacers, 'spacing', '6');
}

.widgetInnerWrapper {
  border-radius: map.get($radiuses, '3');
  background-color: map.get($colors, 'lily-of-the-valley');
  padding: map.get($spacers, 'spacing', '5') map.get($spacers, 'spacing', '5');
}
