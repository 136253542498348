@use 'sass:map';

@use '@shared/styles/config/variables' as *;

.root {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 56.25%;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: map.get($radiuses, '4');
  }
}