@use 'sass:map';

@use '@shared/styles/config/variables' as *;
@use '@shared/styles/mixins/breakpoint' as *;
@use '@shared/styles/mixins/spacing' as *;

.root {
  &:last-child {
    padding-bottom: map.get($spacers, 'spacing', '7');

    @include breakpoint('lg', 'min') {
      padding-bottom: map.get($spacers, 'spacing', '12');
    }

    @include breakpoint('xl', 'min') {
      padding-bottom: map.get($spacers, 'spacing', '15');
    }
  }

  [class*="Button_large__"] {
    &:not([class*="Button_icon__"]) {
      padding: 0 0;
    }
  }
}

.hero {
  background-color: map.get($colors, 'lily-of-the-valley');
  overflow: hidden;
  padding: map.get($spacers, 'spacing', '7') 0;
  position: relative;

  @include breakpoint('lg', 'min') {
    padding: map.get($spacers, 'spacing', '12') 0;
    text-align: left;
  }

  .title {
    @include spacing('md', 'bottom', 'margin');
  }

  > * {
    position: relative;
  }
}

.posts {
  padding-top: map.get($spacers, 'spacing', '7');

  @include breakpoint('lg', 'min') {
    padding-top: map.get($spacers, 'spacing', '12');
  }

  @include breakpoint('xl', 'min') {
    padding-top: map.get($spacers, 'spacing', '15');
  }
}

.author {
  [class*="Card_links__"] {
    order: 4;
    margin-top: map.get($spacers, 'spacing', '6');

    &:not(:last-child) {
      margin-bottom: 0;
    }
  }

  [class*="Card_editor__"] {
    order: 3;
  }

  [class*="Card_title__"] {
    order: 2;

    &:not(:last-child) {
      margin-bottom: auto;
      padding-bottom: map.get($spacers, 'spacing', '3');
    }
  }

  [class*="Card_image__"] {
    order: 1;
    padding-top: 30%;

    img {
      width: 5.625rem;
      height: 5.625rem;
      border-radius: 100%;
    }
  }
}