@use 'sass:map';

@use '@shared/styles/config/variables' as *;
@use '@shared/styles/mixins/spacing' as *;

.root {
  @include spacing('md', 'bottom', 'margin');
  @include spacing('md', 'top', 'margin');

  ul {
    display: flex;
    flex-wrap: wrap;
    margin: - map.get($spacers, 'spacing', '3');
  }

  li {
    padding: map.get($spacers, 'spacing', '3');
  }
}
