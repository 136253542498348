@use 'sass:map';

@use '@shared/styles/config/variables' as *;
@use '@shared/styles/mixins/breakpoint' as *;
@use '@shared/styles/mixins/spacing' as *;

.root {
  background-color: map.get($colors, 'lily-of-the-valley');
  padding: map.get($spacers, 'spacing', '7') 0;

  @include breakpoint('lg', 'min') {
    padding: map.get($spacers, 'spacing', '12') 0;
  }

  @include breakpoint('xl', 'min') {
    padding: map.get($spacers, 'spacing', '15') 0;
  }

  .subtitle {
    margin-bottom: map.get($spacers, 'spacing', '4');

    a {
      &:not(:hover) {
        color: map.get($colors, 'purple-rain');
      }
    }
  }

  li {
    &:not(:last-child) {
      margin-bottom: map.get($spacers, 'spacing', '3');
    }

    a {
      &:not(:hover) {
        color: map.get($colors, 'charcoal-charlie');
      }
    }
  }
}
