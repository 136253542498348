@use 'sass:map';

@use '@shared/styles/config/variables'as *;
@use '@shared/styles/mixins/spacing'as *;
@use '@shared/styles/mixins/breakpoint'as *;


.root {
  @include spacing('md', 'bottom', 'margin');

  @include breakpoint('md', 'min') {
    display: flex;
  }
}

.metaSeparator {
  margin: 0 map.get($spacers, 'spacing', '3');
}

.authorBy {
  margin-left: map.get($spacers, 'spacing', '4');
  margin-right: map.get($spacers, 'spacing', '2') + map.get($spacers, 'spacing', '1');
}

.authorDetails {
  display: flex;
  align-self: center;

  @include breakpoint('sm', 'max') {
    margin-top: map.get($spacers, 'spacing', '3');
    flex-direction: column;
    align-items: start !important;

    span {
      &:first-of-type {
        margin-left: 0;
      }
    }
  }
}

.readTime {
  @include breakpoint('sm', 'max') {
    .metaSeparator {
      display: none;
    }
  }
}

.authors {
  display: flex;

  @include breakpoint('md', 'min') {
    align-self: center;
  }
}