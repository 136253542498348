@use 'sass:map';

@use '@shared/styles/config/variables' as *;
@use '@shared/styles/mixins/breakpoint' as *;
@use '@shared/styles/mixins/spacing' as *;

.root {
  background-color: map.get($colors, 'white');
  border-radius: map.get($radiuses, '4');
  border: map.get($borders, '1') solid map.get($colors, 'blackbird-500');
  padding: map.get($spacers, 'spacing', '4');
  scroll-margin-top: map.get($snaps, 'offset', 'mobile');

  @include spacing('md', 'bottom', 'margin');

  @include breakpoint('lg', 'min') {
    padding: map.get($spacers, 'spacing', '5');
    scroll-margin-top: map.get($snaps, 'offset', 'desktop');
  }

  .ctaButtonWrapper {
    display: flex;
    gap: map.get($spacers, 'spacing', '4');
    margin-top: map.get($spacers, 'spacing', '4');
    margin-bottom: map.get($spacers, 'spacing', '5');

    @include breakpoint('md', 'max') {
      flex-direction: column;
    }

    .links {
      margin-top: 0;

      @include breakpoint('md', 'max') {
        width: 100%;
      }
    }
  }
}

.topic {
  display: flex;
  flex-direction: column;

  .ctaButtonWrapper {
    order: 5;
    margin-bottom: 0;
    flex-wrap: wrap;
  }
}

.image {
  object-fit: contain;
  object-position: left center;

  @include spacing('md', 'bottom', 'margin');
}

.title {
  @include spacing('md', 'bottom', 'margin');

  a {
    color: map.get($colors, 'charcoal-charlie');
  }
}

.cta {
  @include breakpoint('md', 'max') {
    min-width: 100%;
  }
}

.links {
  li {
    @include breakpoint('md', 'max') {
      flex-basis: 100%;
    }
  }

  a {
    min-width: 100%;
  }
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.chip {
  margin-bottom: map.get($spacers, 'spacing', '4');
}

.previewLinks {
  display: flex;
  flex-wrap: wrap;
  gap: map.get($spacers, 'spacing', '4');

  @include spacing('md', 'top', 'margin');
  @include spacing('md', 'bottom', 'margin');

  a {
    text-decoration: underline;
    text-decoration-thickness: map.get($spacers, 'spacing', '1');
    text-underline-offset: map.get($spacers, 'spacing', '1');
  }
}

.text {
  order: 4;
}
