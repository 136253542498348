@use 'sass:map';
@use 'sass:math';

@use '@shared/styles/config/variables' as *;
@use '@shared/styles/mixins/breakpoint' as *;

.root {
  display: flex;
  flex-flow: column;
  line-height: 1;

  hr {
    border-style: solid;
    border-width: math.div(map.get($borders, '3'), 3);
    margin: map.get($spacers, 'spacing', '3') 0;

    @each $border in map.get($configs, 'border') {
      &.#{$border} {
        border-color: map.get($colors, $border);
      }
    }

    @include breakpoint('sm', 'min') {
      margin: map.get($spacers, 'spacing', '4') 0;
    }
  }
}

.purple {
  color: map.get($colors, 'purple-rain');
}
